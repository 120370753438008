<template>
  <div class="btn" :class="{btn__inverse: inverse}">
    <div class="btn__icon" v-if="$slots.icon">
      <slot name="icon"/>
    </div>
    <div class="btn__content"><slot/></div>
  </div>
</template>

<script>
export default {
  name: "Btn",
  props: {
    inverse: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/variables";

  .btn{
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
    width: 100%;
    padding: 0 10px;
    line-height: 17px;

    &__content, &__icon{
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
    }

    &__icon + &__content{
      margin-left: 8px;
    }

    &__content{
      padding-bottom: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient($color-bg, $color-bg) padding-box, $gradient border-box;
      border-radius: 8px;
      border: 1px solid transparent;
    }

    &:after{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: $gradient;
      border-radius: 8px;
      transition: opacity 0.2s;
    }

    &:hover:after{
      opacity: 0;
    }

    &__inverse{
      &:after{
        opacity: 0;
      }

      &:hover:after{
        opacity: 1;
      }
    }
  }
</style>