export default {
    isSafari()
    {
        // eslint-disable-next-line
        return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    },
    isIos()
    {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent) ||
            navigator.userAgent.includes("Mac") && "ontouchend" in document;
    },
    PWAInstallPrompt()
    {
        return new Promise(r => {
            window.addEventListener('beforeinstallprompt',prompt => {
                r(() => {
                    prompt.prompt();

                    return new Promise((r, re) => {

                        const l = () => {
                          window.removeEventListener('appinstalled', l);
                          r();
                        };

                        window.addEventListener('appinstalled', l);

                        prompt.userChoice.then(e => {
                            window.removeEventListener('appinstalled', l);

                            if (e.outcome === 'accepted') {
                                r();
                                return;
                            }

                            re();
                        });
                    });
                });
            });
        });
    }
}